import {APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom, inject, provideZoneChangeDetection} from '@angular/core';
import {environment} from './environments/environment';
import * as Sentry from "@sentry/angular";
import {getWhitelabelEnvironment} from "./app/core/logic/whitelabel.logic";
import {bootstrapApplication} from "@angular/platform-browser";
import {AppComponent} from "./app/app.component";
import {provideRouter, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {PendingChangesGuard} from "./app/core/guards/pending-changes.guard";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {HttpConfigInterceptor} from "./app/core/interceptors/http-config.interceptor";
import {API_BASE_URL} from "./app/core/swagger/generated/swagger-client";
import {HttpLink} from "apollo-angular/http";
import {ApolloClientOptions, InMemoryCache} from "@apollo/client/core";
import {APOLLO_OPTIONS, provideApollo} from "apollo-angular";
import {provideTransloco} from "@jsverse/transloco";
import {TranslocoHttpLoader} from "./app/transloco-loader";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ClientAuthenticationGuard} from "./app/core/guards/client-authentication.guard";
import {ClientAuthenticationRejectGuard} from "./app/core/guards/client-authentication-reject.guard";
import {ClientExpiredLicenseGuard} from "./app/core/guards/client-expirated-license.guard";
import {PartnerAuthenticationGuard} from "./app/core/guards/partner-authentication.guard";
import {PartnerAuthenticationRejectGuard} from "./app/core/guards/partner-authentication-reject.guard";
import {routes} from "./app/app.routes";
import {GlobalErrorHandler} from "./app/core/interceptors/error.interceptor";

if (environment.production) {
  enableSentry();
  enableProdMode();
}

const whitelabel = getWhitelabelEnvironment();
document.documentElement.style.setProperty('--doffice-gold', whitelabel.app.accentColor);

export function GetApiBaseUrl() {
  return getWhitelabelEnvironment().api.url;
}

bootstrapApplication(AppComponent, {
  providers: [
    ClientAuthenticationGuard,
    ClientAuthenticationRejectGuard,
    ClientExpiredLicenseGuard,
    PartnerAuthenticationGuard,
    PartnerAuthenticationRejectGuard,
    PendingChangesGuard,
    provideZoneChangeDetection({eventCoalescing: true}),
    importProvidersFrom([BrowserAnimationsModule]),
    provideRouter(routes),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    DatePipe,
    PendingChangesGuard,
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
    {
      provide: API_BASE_URL,
      useFactory: GetApiBaseUrl
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideApollo(() => {
      const httpLink = inject(HttpLink);

      return {
        link: httpLink.create({uri: whitelabel.api.url + "/graphql"}),
        cache: new InMemoryCache(),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache'
          },
          query: {
            fetchPolicy: 'no-cache',
          },
          mutate: {
            fetchPolicy: 'no-cache'
          }
        }
      };
    }),
    provideTransloco({
      config: {
        availableLangs: ['en', 'nl', 'fr', 'de'],
        defaultLang: 'nl',
        fallbackLang: 'nl',
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader
    }),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ]
})
  .catch((err) => console.error(err));

function enableSentry() {
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
      })
    ],
    tracePropagationTargets: environment.sentry.tracePropagationTargets,
    tracesSampleRate: environment.sentry.tracesSampleRate, // Capture 100% of the transactions
    replaysSessionSampleRate: environment.sentry.replaySessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
